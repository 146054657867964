import './App.css';
import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router';
import { lazy } from 'react';
import api from './api';
import { isOnlineCity } from './utils/common';


const MainPage = lazy(() => import('./pages/main/main-screen'));
const FaqPage = lazy(() => import('./pages/faq/faq-screen'));

function App() {
  const location = useLocation();

  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState({});
  const [formats, setFormats] = useState([]);

  const [isOpenChoiceCityModal, setIsOpenChoiceCityModal] = useState(false);

  useEffect(() => {
    getCities();
  }, []);

  const getCities = () => {
    fetch("cities.json")
      .then((r) => r.json())
      .then((r) => {
          setCities(r.values);
          setCity(r.values);
      })
      .catch((err) => console.error(err));
  };

  const getFormats = (publicId) => {
    api.get(`/api/event_format/event/${publicId}`)
      .then((r) => {
        setFormats(r.data.values);
      })
  };

  const getLocalCity = () => {
    const localCity = localStorage.getItem(`city`);

    if (localCity) {
      try {
        const parseCity = JSON.parse(localCity);

        if (typeof parseCity === 'object') {
          return parseCity;
        }
      } catch {
        localStorage.removeItem(`city`)
      }
    }

    return null;
  };

  const setCity = (cities) => {
    const defaultCity = `zabeg2023_498817`;
    const defaultCityOnline = `zabeg2023_online_473247_1`;
    const moscowId = `zabeg2023_524901`;
    const localCity = getLocalCity(cities);

    if (location.pathname === `/moscow` || location.pathname === `/%D0%BC%D0%BE%D1%81%D0%BA%D0%B2%D0%B0`) {
      const findCity = cities.find((item) => item.public_id === moscowId);

      if (findCity) {
        setSelectedCity(findCity);
        getFormats(findCity.public_id);
      }
    } else {
      if (localCity) {
        const findCity = cities.find((item) => item.public_id === localCity.public_id);

        if (findCity) {
          setSelectedCity(findCity);
          getFormats(findCity.public_id);

          if (location.pathname !== `/online` && isOnlineCity(findCity)) {
            window.location.replace('/online');
          } else if (location.pathname === `/online` && !isOnlineCity(findCity)) {
            window.location.replace('/');
          }
        }
      } else {
        api.get(`/api/location/detailed`)
          .then((r) => {
            const findCity = cities.find((item) => item.city.id === r.data.info.city.id);

            if (findCity && ((location.pathname !== `/online` && !isOnlineCity(findCity)) || (location.pathname === `/online` && isOnlineCity(findCity)))) {
              getFormatsAndSetSelectedCity(findCity);
            } else {
              let findCity;

              if (location.pathname === `/online`) {
                findCity = cities.find((item) => item.public_id === defaultCityOnline);
              } else {
                findCity = cities.find((item) => item.public_id === defaultCity);
              }

              if (findCity) {
                getFormatsAndSetSelectedCity(findCity);
              }
            }
          })
      }
    }
  };

  const getFormatsAndSetSelectedCity = (city) => {
    setSelectedCity(city);
    getFormats(city.public_id);
    localStorage.setItem(`city`, JSON.stringify(city));
  }

  const handleChangeCity = (cityPublicId) => {
    const findCity = cities.find((item) => item.public_id === cityPublicId);

    if (findCity) {
      setSelectedCity(findCity);
      getFormats(cityPublicId);
      localStorage.setItem(`city`, JSON.stringify(findCity));

      if (location.pathname !== `/online` && isOnlineCity(findCity)) {
        window.location.replace('/online');
      } else if (location.pathname === `/online` && !isOnlineCity(findCity)) {
        window.location.replace('/');
      }
    }
  };

  return (
    <Routes>
      <Route path="/" element={<MainPage cities={cities} formats={formats} selectedCity={selectedCity} onChangeCity={handleChangeCity} isOpenChoiceCityModal={isOpenChoiceCityModal} onOpenChoiceCityModal={setIsOpenChoiceCityModal} />} />
      <Route path="/moscow" element={<MainPage cities={cities} formats={formats} selectedCity={selectedCity} onChangeCity={handleChangeCity} isOpenChoiceCityModal={isOpenChoiceCityModal} onOpenChoiceCityModal={setIsOpenChoiceCityModal} />} />
      <Route path="/москва" element={<MainPage cities={cities} formats={formats} selectedCity={selectedCity} onChangeCity={handleChangeCity} isOpenChoiceCityModal={isOpenChoiceCityModal} onOpenChoiceCityModal={setIsOpenChoiceCityModal} />} />
      <Route path="/faq" element={<FaqPage cities={cities} selectedCity={selectedCity} onChangeCity={handleChangeCity} isOpenChoiceCityModal={isOpenChoiceCityModal} onOpenChoiceCityModal={setIsOpenChoiceCityModal} />} />
      <Route path="/вопрос-ответ" element={<FaqPage cities={cities} selectedCity={selectedCity} onChangeCity={handleChangeCity} isOpenChoiceCityModal={isOpenChoiceCityModal} onOpenChoiceCityModal={setIsOpenChoiceCityModal} />} />
    </Routes>
  );
}

export default App;
